
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { ProdutoService, AlmoxarifadoService, TipoMovimentacaoService, TipoOrigemService, TipoProdutoService } from "@/core/services/almoxarifado";
import { Produto } from "@/core/models/almoxarifado";

@Component
export default class CfgMovimentacao extends Vue {
  item: {
    id: number;
    empreendimentoId: number;
    almoxarifadoId: number;
    produtoId: number;
    tipoId: number;
    tipoOrigemId: number;
    dataInicial: string;
    dataFinal: string;
    tipoProdutoId:number;
  } = {
    id: 0,
    empreendimentoId: 0,
    almoxarifadoId: 0,
    produtoId: 0,
    tipoId: 0,
    tipoProdutoId:0,
    tipoOrigemId: 0,
    dataInicial: "",
    dataFinal: "",
  };

  valid = true;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  empreendimentos:any = [];
  almoxarifados:any = [];
  tipos:any = [];
  tipoOrigens:any = [];

  produto: Produto = new Produto();
  onSearchProduto: any = null;
  isProdutoLoading: boolean = false;
  listaProdutos:any = [];

  @Watch('produto')
  ProdutoChange(produto: Produto){
    if (produto){
      if (produto.id == 0){
        this.item.produtoId = 0;
        return;
      }
      this.item.produtoId = produto.id;
     }
    else {
      this.LimparProduto();
    }
  }

  @Watch('onSearchProduto')
  SearchProduto (val: string) {
    if (this.isProdutoLoading) return;
    if (!val) return;

    this.isProdutoLoading = true
    new ProdutoService().AutoComplete(val)
    .then(
        res => {
          this.listaProdutos = res.data;
        },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => (this.isProdutoLoading = false));
  }

  LimparProduto(){
    this.produto = new Produto();
  }

  tipoProdutos:any = [];

  mounted() { 
    new TipoProdutoService().ListarTudo().then(
      res => { 
        this.tipoProdutos = res.data.items
        this.tipoProdutos.sort((x,y) => (x.nome > y.nome) ? 1 : ((y.nome > x.nome) ? -1 : 0))
      }
    )
    
    const empreendimentoService = new EmpreendimentoService();
    empreendimentoService.ListarTudo().then(
      (res) => {
        this.empreendimentos = res.data.items;
        this.empreendimentos.sort((x,y) => (x.nome > y.nome) ? 1 : ((y.nome > x.nome) ? -1 : 0))
      },
      (err) => this.$swal("Aviso", err.message, "error")
    );
    
    const almoxarifadoService = new AlmoxarifadoService();
    almoxarifadoService.ListarTudo().then(
      (res) => {
        this.almoxarifados = res.data.items;
        this.almoxarifados.sort((x,y) => (x.nome > y.nome) ? 1 : ((y.nome > x.nome) ? -1 : 0))
      },
      (err) => this.$swal("Aviso", err.message, "error")
    );

    const tipoService = new TipoMovimentacaoService();
    tipoService.ListarTudo().then(
      (res) => {
        this.tipos = res.data.items;
        this.tipos.sort((x,y) => (x.nome > y.nome) ? 1 : ((y.nome > x.nome) ? -1 : 0))
      },
      (err) => this.$swal("Aviso", err.message, "error")
    );

    const tipoOrigemService = new TipoOrigemService();
    tipoOrigemService.ListarTudo().then(
      (res) => {
        this.tipoOrigens = res.data.items;
        this.tipoOrigens.sort((x,y) => (x.nome > y.nome) ? 1 : ((y.nome > x.nome) ? -1 : 0))
      },
      (err) => this.$swal("Aviso", err.message, "error")
    );

  }

  Visualizar() {
    if(!this.item.dataInicial || !this.item.dataFinal){
        this.$swal("Aviso", "Obrigatório preencher início e fim da data.", "warning");
        return true;
    }    
    let routeLink = this.$router.resolve({name: 'relMovimentacao',
      query:{
        id: this.item.id.toString(),
        empreendimentoId: this.item.empreendimentoId ? this.item.empreendimentoId.toString() : '0',
        almoxarifadoId: this.item.almoxarifadoId ? this.item.almoxarifadoId.toString() : '0',
        produtoId: this.item.produtoId ? this.item.produtoId.toString() : '0',
        tipoId: this.item.tipoId ? this.item.tipoId.toString() : '0',
        tipoOrigemId: this.item.tipoOrigemId ? this.item.tipoOrigemId.toString() : '0',
        tipoProdutoId: this.item.tipoProdutoId ? this.item.tipoProdutoId.toString() : '0',
        dataInicial: this.item.dataInicial,
        dataFinal: this.item.dataFinal,
      }
    });
    window.open(routeLink.href, '_blank');
  }
}
